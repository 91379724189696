import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { CircleSpinner, Dialog, FormInputSearch } from '@nordic-web/ui-components'
import { useUserQuery } from '@/features/user/hooks/use-user-query'
import { trackDevEvent } from '@/tracking/track-dev-event'
import { FilterTypes } from './components/filter-tabs'

const LazyListSearch = dynamic(() => import('@/features/search/views/list-search').then((mod) => mod.ListSearch), {
  ssr: false,
})
const LazySearchHistory = dynamic(
  () => import('@/features/search/components/search-history').then((mod) => mod.SearchHistory),
  { ssr: false, loading: () => <CircleSpinner nwPaddingTop /> }
)

const CustomDialog = styled(Dialog)({
  height: '100%',
})

export const SearchModal = () => {
  const t = useTranslations()
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const { register, handleSubmit } = useForm<{ search: string }>()
  const { isChildProfile } = useUserQuery()

  useEffect(() => {
    const downHandler = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setIsOpen((open) => !open)
      }

      if (!isOpen) {
        // don't do anything if the modal is closed
        return
      }

      const activeElement = document.activeElement as HTMLElement
      const focusableElements = Array.from(document.querySelectorAll('.list-card, input')) as HTMLElement[]
      const currentElementIndex = focusableElements.indexOf(activeElement)

      if (e.key === 'ArrowDown') {
        e.preventDefault()
        const next = focusableElements[currentElementIndex + 1]
        if (next) next.focus()
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault()
        const prev = focusableElements[currentElementIndex - 1]
        if (prev) prev.focus()
      }
    }

    document.addEventListener('keydown', downHandler)
    return () => document.removeEventListener('keydown', downHandler)
  }, [isOpen])

  const submitHandler = handleSubmit(() => {
    const firstResult = document.querySelector('.list-card') as HTMLElement
    if (firstResult) {
      firstResult.click()
    }
  })

  useEffect(() => {
    if (isOpen) {
      trackDevEvent({
        label1: 'impression',
        label2: 'CtrlKSearchModal',
      })
    }
  }, [isOpen])

  return (
    <CustomDialog
      id="search-modal"
      nwInitOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
        setSearchTerm('')
      }}
    >
      <Dialog.Body>
        <form onSubmit={submitHandler}>
          <FormInputSearch
            onInput={(e) => setSearchTerm(e.currentTarget.value)}
            value={searchTerm}
            validators={register('search')}
            nwLabel={t('search__input_label')}
            placeholder={t('search__searchfield_pre_typing')}
            nwClearButtonLabel={t('search__search_history__clear')}
            enterKeyHint="search"
          />
        </form>
        {isOpen && (
          <>
            {searchTerm !== '' ? (
              <LazyListSearch
                isChildProfile={isChildProfile}
                onCardClick={() => setIsOpen(false)}
                searchTerm={searchTerm}
                scrollableTarget="search-modal"
                filter={FilterTypes.ALL}
              />
            ) : (
              <LazySearchHistory onCardClick={() => setIsOpen(false)} forceMobile />
            )}
          </>
        )}
      </Dialog.Body>
    </CustomDialog>
  )
}
