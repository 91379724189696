import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import * as AccountService from '@nordic-web/rest-codegen/generated/account'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { authenticationStore, logout } from '@/features/auth/authentication-store'
import { AuthTracking } from '@/features/auth/tracking-events'
import { useUserQuery } from '@/features/user/hooks/use-user-query'
import { nextConfig } from '@/helpers/env'
import { getPageName } from '@/helpers/get-page-name'

const ACCOUNT_HOST_API = nextConfig.string('ACCOUNT_HOST_API')

AccountService.OpenAPI.BASE = ACCOUNT_HOST_API

export const useAccountApi = () => {
  const { user } = useUserQuery()

  const logoutOptions: UseMutationOptions = {
    onSuccess: () => {
      AuthTracking.onLogoutSuccess(user?.details.id ?? '', getPageName() ?? '')
    },
    onSettled: () => {
      logout()
    },
  }

  const logoutMutation = useMutation({
    mutationFn: async () => {
      const token = await authenticationStore.getValidAccessToken()
      return AccountService.accountWebSessionControllerLogout({
        authorization: formatAuthorizationHeader(token),
      })
    },
    ...logoutOptions,
  })

  const logoutAllMutation = useMutation({
    mutationFn: async () => {
      const token = await authenticationStore.getValidAccessToken()
      return AccountService.accountWebSessionControllerLogoutAll({
        authorization: formatAuthorizationHeader(token),
      })
    },
    ...logoutOptions,
  })

  return {
    logoutMutation,
    logoutAllMutation,
  }
}
