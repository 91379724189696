import { useUserQuery as useUserQueryInternal } from '@nordic-web/gql'
import { useAuthenticationStore } from '@/features/auth/authentication-store'

export const useUserQuery = () => {
  const { isLoggedIn } = useAuthenticationStore()
  const { data, loading: isLoadingUser } = useUserQueryInternal({
    ssr: false,
    skip: !isLoggedIn,
    pollInterval: 60 * 60_000,
  })

  const isChildProfile = !!data?.user.activeProfile?.isChild

  return { user: data?.user, isLoadingUser, isChildProfile }
}
