import React from 'react'
import { useTranslations } from 'next-intl'
import { TypographyText, TypographyTitle, useNotificationSnackbar } from '@nordic-web/ui-components'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { AuthModalPaths, ChildLockFormViews } from '@/features/auth/types'
import { DISABLE_CHILD_LOCK_METHODS } from '@/features/child-lock/manage/child-lock.constants'
import { DisableWithPassword } from '@/features/child-lock/manage/forms/disable-with-password'
import { DisableWithPinCode } from '@/features/child-lock/manage/forms/disable-with-pin-code'
import { useUpdateUserDetailsMutation } from '@/features/user/hooks/use-update-user-details-mutation'
import { useUserQuery } from '@/features/user/hooks/use-user-query'

const PinCodeTexts = ({ isChangingPin }: { isChangingPin?: boolean }) => {
  const t = useTranslations()
  return (
    <>
      <TypographyTitle as="h2">
        {isChangingPin ? t('child_lock__change_pin_code') : t('child_lock__turn_off_headline')}
      </TypographyTitle>
      <TypographyText nwVariant="body4" nwColor="secondary" nwTextAlign="center">
        {isChangingPin ? t('child_lock__edit_pin_code') : t('child_lock__turn_off_description')}
      </TypographyText>
    </>
  )
}

const PasswordTexts = ({ isChangingPin }: { isChangingPin?: boolean }) => {
  const t = useTranslations()
  return (
    <>
      <TypographyTitle as="h2">
        {isChangingPin ? t('child_lock__change_pin_code') : t('child_lock__turn_off_headline')}
      </TypographyTitle>
      <TypographyText nwVariant="body4" nwColor="secondary" nwTextAlign="center">
        {t('child_lock__pin_code_forgotten')}
      </TypographyText>
    </>
  )
}

type PinCodeForm = {
  pinCode: string
}

type ChildLockDisableProps = {
  onCancelChildLock: () => void
  method: DISABLE_CHILD_LOCK_METHODS
  email?: string
  pinCode: string
  isChangingPin?: boolean
}

export const ChildLockDisable = ({
  onCancelChildLock,
  method,
  email,
  pinCode,
  isChangingPin,
}: ChildLockDisableProps) => {
  const { dispatch } = useAuthModal()

  const { user } = useUserQuery()

  const { updateUser, error, isLoading } = useUpdateUserDetailsMutation()

  const { showSnackbarMessage } = useNotificationSnackbar()
  const t = useTranslations()

  const onSubmit = async ({ pinCode }: PinCodeForm) => {
    if (user?.details.childLockPinCode !== pinCode) return

    await updateUser({
      variables: {
        input: {
          childLockPinCode: undefined,
        },
      },
    })

    if (isChangingPin) {
      dispatch({
        type: 'show-auth-modal',
        path: AuthModalPaths.CHILDLOCK,
        pathParams: { formView: ChildLockFormViews.ENABLE_PIN },
      })
    } else {
      showSnackbarMessage({ text: t('snackbar__settings__child_lock_disabled') })
      onCancelChildLock()
    }
  }

  if (!pinCode) return null

  if (method === DISABLE_CHILD_LOCK_METHODS.PIN) {
    const errorMessage = error?.message || ''

    return (
      <>
        <PinCodeTexts isChangingPin={isChangingPin} />
        <DisableWithPinCode
          errorMessage={errorMessage}
          loading={isLoading}
          onSubmit={onSubmit}
          isChangingPin={isChangingPin}
          onClose={onCancelChildLock}
          pinCode={pinCode}
        />
      </>
    )
  } else if (method === DISABLE_CHILD_LOCK_METHODS.PASSWORD) {
    return (
      <>
        <PasswordTexts isChangingPin={isChangingPin} />
        <DisableWithPassword email={email} isChangingPin={isChangingPin} onClose={onCancelChildLock} />
      </>
    )
  } else return null
}
