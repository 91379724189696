import React, { useState } from 'react'
import { useTranslations } from 'next-intl'
import { TypographyText, TypographyTitle, useNotificationSnackbar } from '@nordic-web/ui-components'
import { EditPinCode } from '@/features/child-lock/manage/forms/edit-pin-code'
import { isValidPinCode } from '@/features/settings/components/child-lock/validation'
import { useUpdateUserDetailsMutation } from '@/features/user/hooks/use-update-user-details-mutation'

type ChildLockEnableProps = {
  onCancelChildLock: () => void
  pinCode: string
}

type PinCodeForm = {
  pinCode: string
}

export const ChildLockEnable = ({ onCancelChildLock, pinCode }: ChildLockEnableProps) => {
  const t = useTranslations()

  const { updateUser, error, isLoading } = useUpdateUserDetailsMutation()

  const [formError, setFormError] = useState<string | undefined>()
  const { showSnackbarMessage } = useNotificationSnackbar()

  const onSubmit = async ({ pinCode }: PinCodeForm) => {
    if (!isValidPinCode(pinCode)) {
      setFormError(t('child_lock__pin_error'))
      return
    }
    await updateUser({
      variables: {
        input: {
          childLockPinCode: pinCode,
        },
      },
    })

    showSnackbarMessage({ text: t('snackbar__settings__child_lock_enabled') })
    onCancelChildLock()
  }

  const errorMessage = error?.message
  return (
    <>
      <TypographyTitle as="h2">{t('settings__child_lock__add_pincode__title')}</TypographyTitle>
      <TypographyText nwVariant="body4" nwColor="secondary" nwTextAlign="center">
        {t('settings__child_lock__add_pincode__description')}
      </TypographyText>

      <EditPinCode
        errorMessage={formError || errorMessage}
        loading={isLoading}
        disabled={isLoading}
        isCancellable={!pinCode}
        onCancel={() => {
          onCancelChildLock()
        }}
        onSubmit={onSubmit}
        pinCode={pinCode}
      />
    </>
  )
}
