import type { PropsWithChildren } from 'react'
import React from 'react'
import { useRouter } from 'next/router'
import styled, { createGlobalStyle, css } from 'styled-components'
import { TierOverrideSelect } from '@/components/tier-override-select'
import { useUserQuery } from '@/features/user/hooks/use-user-query'
import { nextConfig } from '@/helpers/env'
import { isWebviewRequest, useNativeWebviewStyling } from '@/hooks/use-native-webview-styling'
import { mediaLargeDesktopHeader, mediaTabletHeader } from '@/layouts/header/style'
import { PreviewDeployRedirect } from '@/layouts/preview-deploy-redirect'
import { stringFromQueryParam } from '@/utils/query-string'
import { FooterComponent as Footer } from './footer'
import { HeaderContainer, HeaderSpacing } from './header/header-container'

export const TOP_PROMO_CONTENT_COLOR_VAR_NAME = '--top-promo-content-color'
export const TOP_PROMO_CONTENT_HEIGHT = '--top-promo-height'

const isPreviewDeploy = nextConfig.bool('IS_PREVIEW_DEPLOY')

const GlobalAppStyle = createGlobalStyle<{ contentColorVar?: string }>`
  :root {
    --content-color: ${({ contentColorVar, theme }) =>
      contentColorVar ? `var(${contentColorVar})` : theme.color.base.background};

    --page-top-padding: ${({ theme }) => theme.space(8)};

    /* The format of this var is important. This var is read by JS and is expected to have the px unit. */
    --header-height: 60px;

    ${mediaTabletHeader`
      --header-height: 70px;
    `}

    ${mediaLargeDesktopHeader`
      --header-height: 80px;
    `}
  }
`

const Container = styled.main<{
  fadeContentColor: boolean
  fadeHeight?: string
  shouldAddBottomPadding: boolean
}>`
  min-height: 80vh;
  position: relative;
  padding-bottom: ${(props) => (props.shouldAddBottomPadding ? props.theme.space(12) : 0)};

  ${(props) => {
    if (props.fadeContentColor) {
      return css`
        background: ${({ theme }) => theme.color.base.background};
        &::before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          height: ${props.fadeHeight};
          width: 100%;
          background: linear-gradient(var(--content-color) 0%, var(--content-color) 30%, transparent 100%);
          z-index: -2; /* Needed due to Promo image having z-index=-1. */
        }
      `
    } else {
      return `background: var(--content-color);`
    }
  }}
`

type AppProps = {
  contentColorVar?: string
  shouldFadeContentColor?: boolean
  fadeHeight?: string
  shouldChangeHeaderAndFooterColor?: boolean
  shouldHideHeaderAndFooter?: boolean
  shouldOverrideHeaderMarginBehaviour?: boolean
  shouldAddBottomPadding?: boolean
}

export const NordicAppWithColorFade = ({
  children,
  shouldOverrideHeaderMarginBehaviour,
}: PropsWithChildren<AppProps>) => {
  return (
    <NordicApp
      contentColorVar={TOP_PROMO_CONTENT_COLOR_VAR_NAME}
      shouldFadeContentColor
      shouldOverrideHeaderMarginBehaviour={shouldOverrideHeaderMarginBehaviour}
      fadeHeight={`calc(var(${TOP_PROMO_CONTENT_HEIGHT}) + max(40vw, 400px))`}
    >
      {children}
    </NordicApp>
  )
}

export const NordicApp = (props: PropsWithChildren<AppProps>) => {
  const {
    children,
    contentColorVar,
    shouldFadeContentColor = false,
    fadeHeight,
    shouldChangeHeaderAndFooterColor = false,
    shouldHideHeaderAndFooter = false,
    shouldOverrideHeaderMarginBehaviour = false,
    shouldAddBottomPadding = true,
  } = props

  const { isChildProfile } = useUserQuery()
  const router = useRouter()
  useNativeWebviewStyling()

  const shouldHideMenu = shouldHideHeaderAndFooter || isWebviewRequest(stringFromQueryParam(router.query.source))
  const shouldShowFooter = !shouldHideMenu && !isChildProfile

  return (
    <>
      <GlobalAppStyle contentColorVar={contentColorVar} />
      {!shouldHideMenu && <HeaderContainer useContentColor={shouldChangeHeaderAndFooterColor} />}
      <Container
        shouldAddBottomPadding={shouldAddBottomPadding}
        fadeContentColor={shouldFadeContentColor}
        fadeHeight={fadeHeight}
      >
        {!shouldOverrideHeaderMarginBehaviour && !shouldHideMenu && <HeaderSpacing />}
        {children}
      </Container>
      {shouldShowFooter && <Footer useContentColor={shouldChangeHeaderAndFooterColor} />}
      <TierOverrideSelect />
      {isPreviewDeploy && <PreviewDeployRedirect />}
    </>
  )
}
