import { useUpdateUserDetailsMutation as useUpdateUserDetailsMutationInternal } from '@nordic-web/gql'
import { updateUserCache } from '@nordic-web/utils/cache/update-user-cache'

export function useUpdateUserDetailsMutation() {
  const [updateUser, { loading: isLoading, error }] = useUpdateUserDetailsMutationInternal({
    update: (cache, { data }) => {
      updateUserCache(cache, data?.updateUser)
    },
  })

  return { updateUser, isLoading, error }
}
