/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useSwipeable } from 'react-swipeable'
import styled from 'styled-components'
import type { IconVariant } from '@nordic-web/ui-components'
import { Button, Dialog, Icon, Stack } from '@nordic-web/ui-components'
import { handleEnterKeyDown } from '@nordic-web/utils/misc/keyboard'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { AuthModalPaths } from '@/features/auth/types'
import { useUserQuery } from '@/features/user/hooks/use-user-query'
import { useOnRouterEvent } from '@/hooks/use-on-router-event'
import { MainMenuItemIds, useMainMenuItems, useSubMenuItems } from '@/layouts/header/hooks'
import { useNoHamburgerMenu } from '@/layouts/header/hooks'
import { MobileMenuProfiles } from './mobile-menu-profiles'
import { ListElement } from './style'

const getIcon = (id: MainMenuItemIds): IconVariant => {
  switch (id) {
    case MainMenuItemIds.HOME:
      return 'home'
    case MainMenuItemIds.NEWS:
      return 'news'
    case MainMenuItemIds.CATEGORIES:
      return 'category'
    case MainMenuItemIds.CHANNELS:
      return 'tv-channels'
    case MainMenuItemIds.SPORT:
      return 'sport'
    case MainMenuItemIds.PACKAGES:
      return 'package'
    case MainMenuItemIds.MYLIST:
      return 'plus'
    case MainMenuItemIds.FAQ:
      return 'alert-info-outlined'
  }
}

const ButtonContainer = styled.div({
  justifySelf: 'start',
})

type MobileMenuProps = {
  isOpen: boolean
  onMenuToggle: () => void
  setMobileMenuOpen: (open: boolean) => void
}

export const MobileMenu = ({ isOpen, onMenuToggle, setMobileMenuOpen }: MobileMenuProps) => {
  const mainMenuItems = useMainMenuItems()
  const { user } = useUserQuery()
  const subMenuItems = useSubMenuItems()
  const t = useTranslations()
  const { dispatch } = useAuthModal()

  useOnRouterEvent('routeChangeStart', () => setMobileMenuOpen(false))

  const shouldShowDesktopMenu = useNoHamburgerMenu()

  useEffect(() => {
    if (isOpen && shouldShowDesktopMenu) onMenuToggle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowDesktopMenu, isOpen])

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      onMenuToggle()
    },
  })

  return (
    <div {...swipeHandlers}>
      <Dialog
        nwInitOpen={isOpen}
        onClose={() => {
          isOpen && onMenuToggle()
        }}
        nwVariant="drawer-small"
      >
        <Dialog.Top />
        <Dialog.Body>
          <nav>
            <Stack nwGap={8}>
              <Stack as="ul" nwGap={6}>
                {mainMenuItems.map((item) => (
                  <ListElement key={item.id}>
                    <Link href={item.route}>
                      <Icon nwVariant={getIcon(item.id)} aria-hidden nwSize="small" />
                      {item.text}
                    </Link>
                  </ListElement>
                ))}
              </Stack>
              <Stack nwGap={6} nwAlignItems="flex-start">
                {user && <MobileMenuProfiles onProfileSelect={onMenuToggle} />}
                <Stack as="ul" nwGap={4}>
                  {subMenuItems.map(({ id, text, onClick }) => {
                    return (
                      <ListElement key={id} onClick={onClick} tabIndex={0} onKeyDown={handleEnterKeyDown(onClick)}>
                        {text}
                      </ListElement>
                    )
                  })}
                </Stack>
                {!user && (
                  <ButtonContainer>
                    <Button
                      onClick={() => {
                        dispatch({ type: 'show-auth-modal', path: AuthModalPaths.SIGNUP })
                      }}
                    >
                      {t('main_menu__create_account')}
                    </Button>
                  </ButtonContainer>
                )}
              </Stack>
            </Stack>
          </nav>
        </Dialog.Body>
      </Dialog>
    </div>
  )
}
