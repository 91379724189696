import type { ApolloCache } from '@apollo/client'
import type { UserQuery } from '@nordic-web/gql'
import { UserDocument } from '@nordic-web/gql'

type User = Omit<UserQuery['user'], '__typename' | 'details'> & {
  details: Partial<Omit<UserQuery['user']['details'], '__typename'>>
}

export const updateUserCache = (cache: ApolloCache<LegitimateAny>, user?: Partial<User> | null) => {
  if (!user) return

  const existingData = cache.readQuery<UserQuery>({ query: UserDocument })

  if (!existingData) return

  const mergedUser: UserQuery['user'] = {
    ...existingData.user,
    ...user,
    details: {
      ...existingData.user.details,
      ...user.details,
    },
  }

  cache.writeQuery<UserQuery>({
    query: UserDocument,
    data: {
      __typename: 'Query',
      user: mergedUser,
    },
  })
}
