import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSubscription } from '@/features/checkout/hooks/use-subscription'
import { useUserQuery } from '@/features/user/hooks/use-user-query'
import { getPageName } from '@/helpers/get-page-name'
import { paths } from '@/helpers/paths'
import { stringFromQueryParam } from '@/utils/query-string'
import { dataLayerPush } from './tracking'

export const PageTracking = () => {
  const router = useRouter()
  const page_name = getPageName()
  const { user } = useUserQuery()
  const userId = user?.details.id
  const activeProfile = user?.activeProfile

  const {
    subscriptionState: { data },
  } = useSubscription()

  useEffect(() => {
    if (data?.active && userId && activeProfile) {
      dataLayerPush({
        event: 'set_user_properties',
        subscription: data.active,
        user_id: userId,
        user_profile_id: activeProfile.id,
        user_profile_type: activeProfile.isChild ? 'child' : 'adult',
      })
    }
  }, [data?.active, userId, activeProfile])

  useEffect(() => {
    let content_media_id: string | undefined
    if (paths.program.isActive(router)) {
      content_media_id = stringFromQueryParam(router.query.params?.[0])
    } else if (paths.video.isActive(router)) {
      content_media_id = stringFromQueryParam(router.query.params?.[0])
    }

    dataLayerPush({
      event: 'page',
      page_name,
      ...(content_media_id && { content_media_id }),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page_name, router.asPath])

  return null
}
